// Triple hyphens on lines 1 and 2 are required by Jekyll in order to
// process SCSS into CSS.
// 
// Note that your code editor may syntax highlight the first import statement
// below as being in an error state due to the hyphens above which are part of
// Jekyll syntax and not SCSS syntax. An invalid syntax error, especially on
// the first meaningful line of code, might be a false positive.

// dependencies imports
@import '_modules/core/_utils';
@import '_modules/core/_media';
@import '_modules/theme/_config';

.bg {
  .header__content {
    background-color: $color-gray-1;
    padding: 1rem 2rem;
    @include media-mobile-only {
      padding: 0;
    }
    &--column {
      div {
        .logo {
          max-width: 200px;
        }
      }
    }
  }
  #menu-trigger {
    top: 18*$pxToRem;
    @include media-mobile-only {
      top: 0;
    }
  }
}

body {
  &.home {
    .section__banner {
      height: 100vh;
      max-height: inherit;
      background-position: center top;
      @include media-mobile-only {
        background-position: 100% top!important;
        background-size: cover;
        height: 70vh!important;
        padding-bottom: 6rem;
      }
      &--block {
        @include media-desktop-only {
          margin-top: 16rem;
        }
        @include media-above-laptop {
          margin-top: 18rem;
        }
        h1 {
          color: $bg-blue;
        }
      }
    }
  }
}
.section__banner {
  height: 100vh;
  //max-height: 43.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: right;
  // @include media-below-desktop {
  //   height: 60vh!important;
  // }
  @include media-mobile-only {
    background-position: 100% top!important;
    background-size: auto;
    height: 100% !important;
  }
  @include media-laptop-only {
    height: 100vh!important;
  }
  &--block {
    z-index: 5;
    position: relative;
    margin-top: 13rem;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: auto;
    @include media-mobile-only {
      margin-top: 32rem;
    }
    @include media-tablet-only {
      margin-top: 11rem;
    }
    @include media-desktop-only {
      margin-top: auto;
    }
    h1 {
      font-size: 60*$pxToRem;
      margin: 0 auto;
      @include media-mobile-only {
        font-size: 40*$pxToRem;
      }
    }
    .heading__breadcrumbs {
      margin-bottom: 2rem;
    }
    .banner-text {
      width: 50%;
      @include media-mobile-only {
        width: unset;
      }
      p {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
    .padding-section-content {
      @include media-mobile-only {
        background-color: #005683;
        padding-bottom: 60px!important;
        &:before {
          background: linear-gradient(to bottom,transparent 0%,#005683 100%);
          top: -79px;
          left: 0;
          content: "";
          display: block;
          height: 80px;
          position: absolute;
          width: 100%;
        }
      }
    }
  }
  nav {
    ul, ol {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.3125rem;
      gap: 1.6875rem;
      li {
        position: relative;
        font-family: $body-font;
        color: $color-white;
        font-size: 22*$pxToRem;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.05em;
        line-height: 1.7142857143;
        text-transform: uppercase;
        @include media-mobile-only {
          font-size: 17*$pxToRem;
          line-height: 1.25;
        }
        &:not(:last-child) {
          &:before {
            display: block;
            position: absolute;
            right: -0.6375rem;
            top: 0.2rem;
            content: "|";
            width: 0.1875rem;
            height: 0.1875rem;
            color: $color-white;
            border-radius: 100%;
            @include media-mobile-only {
              top: 0.275rem;
            }
          }
        }
        a {
          color: $color-white;
          font-weight: 400;
          font-style: normal;
          display: block;
          padding: 0.3125rem 0.25rem;
          &:hover {
            color: $color-accent;
          }
          @include media-mobile-only {
            font-size: 17*$pxToRem;
            line-height: 1.25;
          }
        }
      }
    }
  }
}

.section {
  a {
    border-color: $color-link;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    // font-weight: 600;
    color: $color-link;

    &.inverted {
      color: $color-white;
      border-color: $color-white;

      &:hover {
        color: $color-white;
        background-color: $color-bg-hover;
      }
    }

    &:hover {
      color: $color-link-hover;
      text-decoration: none;
      border-bottom: none;
    }
  }

  a.btn {
    border-bottom: none;
  }
}

.section__blocks {
  [class^="grid-"],
  [class*=' grid-'],
  .grid {
    &.relative {
      z-index: 10;
    }
  }
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  h3 {
    margin: 0;
  }
  li {
    &::marker {
      color: $color-accent;
    }
  }
  iframe {
    @include media-mobile-only {
      max-width: 100%;
    }
  }
  .img-column {
    grid-gap: 100px;
    @include media-mobile-only {
      grid-gap: 40px;
    }
  }
  &--bg {
    background-color: $color-gray-2;
  }
  &--box {
    background-color: $color-white;
    box-shadow: 0 0 10px 0 rgb($color-black,.08);
    padding-top: 0;
    padding-bottom: 0;
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 30px;
      top: -30px;
      left: 0;
      background-color: $color-white;
      width: 100%;
      @include media-mobile-only {
        top: 0;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 30px;
      bottom: -30px;
      left: 0;
      background-color: $color-white;
      width: 100%;
      z-index: 1;
      @include media-mobile-only {
        bottom: 0;
      }
    }
    &-grid {
      align-items: center;
      justify-content: center;
      @include media-mobile-only {
        grid-template-columns: 1fr;
      }
      div {
        &:first-child {
          margin: 3rem auto 0;
          svg {
            fill: $overlay-blue;
            @include media-mobile-only {
              width: 90px;
              height: 90px;
              display: block;
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
        }
        h3 {
          font-family: $body-font;
          color: $color-accent;
          font-size: 30*$pxToRem;
          font-weight: 600;
        }
      }
    }
  }
}

.section__reviews {
  p {
    font-size: 35*$pxToRem;
    font-family: $heading-font;
    line-height: 1.3;
    @include media-mobile-only {
      font-size: 25*$pxToRem;
      line-height: 1.1;
    }
    span {
      color: $color-white;
      font-size: 18*$pxToRem;
      font-family: $body-font;
      font-weight: $body-font-bold;
    }
  }
}

.section__helpful {
  h4 {
    color: $color-accent;
    font-size: 24*$pxToRem;
    font-weight: 600;
    text-decoration: underline;
  }
  .grid-2-col {
    grid-gap: 60px;
    img {
      max-width: 130px;
      margin: 0 auto;
    }
    p {
      color: $color-black;
    }
  }
}

.section__doctor {
  .grid-2-col {
    @include media-tablet-only {
      grid-template-columns: 1fr;
    }
  }
  img {
    @include media-mobile-only {
      max-width: 100%;
      margin-bottom: 2rem;
    }
    @include media-tablet-only {
      max-width: 450px;
      margin: 0 auto 2rem;
    }
  }
}

.section__affiliations {
  .grid-img {
    grid-gap: 30px;
    &-two {
      @include media-mobile-only {
        margin-top: 2rem;
      }
      .align-center {
        display: flex;
        grid-gap: 60px;
        @include media-mobile-only {
          display: block;
          grid-gap: 30px;
        }
        img {
          @include media-mobile-only {
            margin: 0 auto;
          }
          &:first-child {
            width: 150px;
            @include media-mobile-only {
              margin-bottom: 2rem;
            }
          }
          &:last-child {
            width: 80px;
          }
        }
      }
    }
  }
}

.section__specials {
  background-image: url($imagesPath + 'artas-bg2-hires.webp') !important;
  
  @include media-mobile-only {
    background-image: url($imagesPath + 'specials-bg2.webp') !important;
  }
}

.section__training {
  background-color: $color-gray-1;
  .grid-2-col-3-8 {
    grid-gap: 60px;
    @include media-mobile-only {
      grid-gap: 30px;
    }
    @include media-tablet-only {
      grid-template-columns: 1fr;
    }
    img {
      max-width: 450px;
      @include media-mobile-only {
        max-width: 100%;
      }
      @include media-tablet-only {
        margin: 0 auto;
      }
    }
  }
}

.section__contact {
  background-position: bottom center;
  &--column {
    position: relative;
    z-index: 10;
    min-height: 24rem;
    &-align {
      margin-top: 2rem;
      margin-left: 2rem;
      margin-right: 2rem;
      @include media-tablet-only {
        margin-top: -4rem;
        margin-left: 0;
        margin-right: 4rem;
      }
      @include media-laptop-only {
        margin-top: -4rem;
        margin-left: 0;
        margin-right: 4rem;
      }
      @include media-above-laptop {
        margin-top: -4rem;
        margin-left: 0;
        margin-right: 6rem;
      }
    }
    .title {
      a {
        color: $color-white;
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

.section__resources {
  .width-1640 {
    .grid-2-col {
      grid-gap: 30px;
    }
    &.grid-4-col {
      grid-gap: 30px;
    }
  }
  &--box {
    background-color: $color-gray-3;
    color: $color-white;
    cursor: pointer;
    font-size: 16*$pxToRem;;
    min-height: 90px;
    padding: 3rem 4rem 4rem;
    position: relative;
    z-index: 10;
    overflow: hidden;
    text-transform: uppercase;
    &:before {
      background-color: $bg-blue;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all $transition-default;
      width: 10px;
    }
    &:hover {
      &:before {
        width: 100%;
      }
      .relative {
        svg {
          opacity: 0.3;
        }
      }
    }
    &-align {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 10;
      svg {
        margin-top: 0;
        display: block;
      }
    }
    .relative {
      svg {
        fill: $color-white;
        opacity: 0.08;
        position: absolute;
        right: -3rem;
        top: -0.5rem;
        transition: all $transition-default;
      }
    }
  }
}

.section__footer {
  background-color: $color-gray-1;
  padding: 2rem 0 4rem;
  @include media-above-mobile {
    padding: 0;
  }
  &--column {
    //min-height: 18rem;
    &-align {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      text-align: center;
      @include media-above-mobile {
        text-align: inherit;
      }
    }
    img {
      max-width: 160px;
      margin: 0 auto 2rem;
      @include media-above-mobile {
        margin: 0;
      }
      //@include media-laptop-only {
      //  margin: 0 0 auto;
      //}
    }
    .social {
      ul {
        justify-content: center;
        margin-top: 2rem;
        @include media-above-mobile {
          justify-content: flex-end;
          margin-top: 0;
        }
        li {
          a {
            &:hover {
              svg {
                fill: $color-accent;
              }
            }
            svg {
              fill: $color-white;
            }
          }
        }
      }
    }
    .phone {
      //margin: 2rem 0 1rem;
      text-align: center;
      @include media-above-mobile {
        text-align: left;
      }
      @include media-desktop-only {
        //margin: 0;
        text-align: left;
      }
      a {
        color: $color-white;
        display: block;
        margin-bottom: 1rem;
        transition: all $transition-default;
        &:hover {
          color: $color-accent;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    a {
      color: $color-white;
      transition: all $transition-default;
      &:hover {
        color: $color-accent;
      }
    }
  }
}

.page-id-97 {

  .section__banner {
    height: 55vh;
    background-attachment: fixed;

    @include media-mobile-only {
      background-position: 90% 0;
    }

    @include media-above-laptop {
      height: 85vh;
    }
  }

  figure {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    justify-content: center;
    align-items: center;

    svg path {
      padding: 0 2rem 1rem;
      fill: $color-accent;
    }
  
    figcaption {
        font-family: franklin-gothic-urw-1,franklin-gothic-urw;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 1.6875;
        text-transform: initial;
        text-align: left;
      }
  }

  p {
    font-family: franklin-gothic-urw-1,franklin-gothic-urw;
    font-style: normal;
    font-weight: 100;
    letter-spacing: 0px;
    line-height: 1.5;
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
    text-transform: initial;
  }

  .partner {
    h2 {
      font-size: 32*$pxToRem;
    }
    p {
      font-size: 25*$pxToRem;
      line-height: 37*$pxToRem;
      font-weight: 100;

      a {
        color: $bg-blue;
        font-weight: 100;
        border-bottom: 1px solid $bg-blue;
        &:hover {
        font-weight: 100;
        border-bottom: none;
        }
      }
    }
  }

  .checkerboard {

    @include media-mobile-only {
      p {
        margin: 2rem auto;
      }
  
      .section__blocks--box-grid {
        grid-template-columns: 1fr;
  
        div:nth-child(3) {
          order: 4;
        }
  
        div:nth-child(5) {
          order: 6;
        }
  
        div:nth-child(6) {
          order: 6;
        }
      }
  
    }
    
    h3 {
    font-family: trajan-pro-3-1,trajan-pro-3;
    color: $color-black;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: initial;
    text-align: center;
    padding: 0 32*$pxToRem;
    margin: 1rem auto;

    @include media-mobile-only {
      font-size: 36*$pxToRem;
      }
    }

    p {
      font-size: 16*$pxToRem;
      text-align: center;
      padding: 0 2rem;
      line-height: 30*$pxToRem;
    }

  }

  .results {
    figure {
      margin: 0 2rem;
    }

    figcaption {
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.6875;
    margin-top: 1rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
    margin-right: 0rem;
    text-align: center;

    }
  }

  .education,
  .fellowships,
  .reviews {
    background-attachment: fixed;
  }

  .reviews {
    a {
      border-bottom: none;
    }
  }

  .treat {
    display: grid;
    grid-template-columns: auto 3rem auto;
    align-items: center;

    @include media-mobile-only {
      grid-template-columns: 1fr;
    }

    h2 {
      font-size: 32*$pxToRem;
    }

    p {
      font-size: 1.5625rem;
      font-weight: 100;
      line-height: 2.3125rem;
      }
  }


}

.section {
  &__before-after {
    .grid-2-col {
      grid-gap: 4rem;
    }
  }
}

.no-banner {
  @include media-mobile-only {
    margin-top: 150px;
  }
}

svg path {
  padding: 0 2rem 1rem;
  fill: $color-accent;
}

.column {
  max-width: 800px;
  margin: 0 auto;
}

.column:only-child {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.flex {

  svg {
    flex: 0 0 auto;
    @include media-mobile-only {
      // margin-left: -2rem;
    }
  }

  img {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
    margin: 0 auto;
  }

}

.line-height-1 {
  line-height: 1;
}

.sub-title {
  font-family: $body-font;
}

.specials {
  p {
    font-size: 25px;
  }
}

.h4 {
  font-size: 25px;
}

.gap {
  gap: 3rem;
}

.checkmark {
  padding-right: 1rem;
}

.affiliation-logos {
  img {
    max-height: 220px;
    margin: 0 auto;
    width: unset;

    @include media-mobile-only {
      max-height: 100px;
    }
  }
}

.transform {
    background-attachment: fixed;
}

.card {
  background-color: $color-accent;
  padding: 2rem;
  color: $color-white;
  text-align: center;
}

.features {
  svg {
    width: 100px;
    padding-right: 1rem;
  }
}